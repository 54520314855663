import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"

const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 20" />
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 20</h1>
      <p>God kveld mamma!</p>
      <p>Det er ikke uten grunn at jeg drøyet med å pakke gaven klar til deg i dag. Jeg ville jo vente til det ble mørkt ute, slik at omstendighetene var best mulig før vi kunne tenne det 4. lyset i advent sammen.</p>
      <p>Så hva venter du på? Få fyr på kubbelyset før jeg sovner for natta her! Tro, håp, glede, fred og bla bla.</p>
      <p>Nattahilsen fra Litjen, hiihihiihiihihii</p>
    </div>
  </Layout>
)

export default LitjenLuke
